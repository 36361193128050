import React from 'react';
import Image from 'next/image';
import { FormEvent, useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { CheckCircleIcon, EyeIcon, EyeSlashIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { forgotPassword } from '@lib/amplify-auth';
import { Routes } from '@lib/routes';
import Link from 'next/link';
import { ToastOptions, toast } from 'react-toastify';

export default function ResetPasswordCard({
  classNames,
  imageSrc,
  imageAlt,
  email,
  submitCallback,
  setShowResetPasswordView,
}) {
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const toastOptions: ToastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'light',
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    submitCallback(verificationCode, password);
  };

  const resendCode = async (event: FormEvent) => {
    event.preventDefault();
    await forgotPassword(email);
  };

  return (
    <div className={`bg-gray-50 ${classNames}`}>
      <div className="flex justify-between signup-header">
        <Image className="w-36 lg:w-52 md:w-52 sm:w-52" src={imageSrc} width={200} height={100} alt={imageAlt} />
        <Link
          href={`${Routes.register}`}
          className="self-center rounded-md bg-gray-50 px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Sign up
        </Link>
      </div>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 login-height px-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            <FormattedMessage id="login.cta" />
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <CheckCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <FormattedMessage id="verify.code.label2">
                {(placeholder: any) => (
                  <input
                    id="verification-code"
                    name="verification-code"
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-3 pl-11 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-lg sm:leading-6"
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="mt-2">
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <FormattedMessage id="password.label2">
                    {(placeholder: any) => (
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        minLength={8}
                        required
                        className="block w-full rounded-md border-0 py-3 pl-11 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-lg sm:leading-6"
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button type="button" onClick={(e) => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <EyeIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    ) : (
                      <EyeSlashIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="w-full rounded-md bg-blue-600 px-3.5 py-2.5 md:text-xl sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              <FormattedMessage id="resetPassword.button" />
            </button>

            <div className="flex flex-col items-center justify-center gap-2">
              <p className="w-full text-sm text-center  pb-2 border-gray-300">
                Didn&apos;t get a code?{' '}
                <button onClick={resendCode} className="font-medium underline text-indigo-600 hover:text-indigo-500">
                  Resend Code
                </button>
              </p>
              <p className="text-sm">
                You can also{' '}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowResetPasswordView(false);
                  }}
                  className="font-medium underline text-indigo-600 hover:text-indigo-500"
                >
                  continue with email
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
      <footer className="absolute bottom-0 left-1/2 -translate-x-1/2 mb-4">
        <div className="text-sm text-center text-gray-600">
          <FormattedMessage id="page.footer.copyright" />
        </div>
      </footer>
    </div>
  );
}

ResetPasswordCard.propTypes = {
  classNames: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  email: PropTypes.string,
  submitCallback: PropTypes.func.isRequired,
  setResetPassword: PropTypes.func,
};
ResetPasswordCard.defaultProps = {
  classNames: '',
  imageSrc: '/static/image/logo/logo-no-bg.png',
  imageAlt: 'LEX AI Logo',
};
