import React from 'react';
import { EnvelopeIcon, EyeIcon, EyeSlashIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Routes } from '@lib/routes';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
export default function LoginWithCard({ classNames, imageSrc, imageAlt, submitCallback, setShowForgotPasswordView }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    submitCallback(email, password);
  };

  return (
    <div className={`bg-gray-50 ${classNames}`}>
      <div className="flex justify-between signup-header">
        <Image className="w-36 lg:w-52 md:w-52 sm:w-52" src={imageSrc} width={200} height={100} alt={imageAlt} />
        <Link
          href={`${Routes.register}`}
          className="self-center rounded-md bg-gray-50 px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <FormattedMessage id="register.prompt.action" />
        </Link>
      </div>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 login-height px-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            <FormattedMessage id="login.cta" />
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <EnvelopeIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <FormattedMessage id="email.label">
                {(placeholder: any) => (
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-3 pl-11 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-lg sm:leading-6"
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </div>

            <div className="mt-2">
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <FormattedMessage id="password.label">
                    {(placeholder: any) => (
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        minLength={8}
                        required
                        className="block w-full rounded-md border-0 py-3 pl-11 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-lg sm:leading-6"
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button type="button" onClick={(e) => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <EyeIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    ) : (
                      <EyeSlashIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="text-sm">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowForgotPasswordView(true);
                }}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                <FormattedMessage id="forgot.password" />
              </button>
            </div>

            <button
              type="submit"
              className="w-full rounded-md bg-blue-600 px-3.5 py-2.5 md:text-xl sm:text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              <FormattedMessage id="login.button" />
            </button>
          </form>
        </div>
      </div>
      <footer className="absolute bottom-0 left-1/2 -translate-x-1/2 mb-4">
        <div className="text-sm text-center text-gray-600">
          <FormattedMessage id="page.footer.copyright" />
        </div>
      </footer>
    </div>
  );
}

LoginWithCard.propTypes = {
  classNames: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  submitCallback: PropTypes.func.isRequired,
  setShowForgotPasswordView: PropTypes.func,
};
LoginWithCard.defaultProps = {
  classNames: '',
  imageSrc: '/static/image/logo/logo-no-bg.png',
  imageAlt: 'LEX AI Logo',
};
